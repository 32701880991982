<template>
  <div>
    <v-card max-width="650">
      <v-card-title :class="$classes.cardTitle">
        <span class="font-weight-bold">Add Organisation</span>
      </v-card-title>
      <v-card-text class="pa-5">
        <preloader v-if="status.getting" />
        
        <v-alert :value="status.error" type="error" border="left" dense>
          {{ status.error }}
        </v-alert>

        <v-form 
          v-if="!status.getting"
          ref="orgForm"
          @submit.prevent="validateForm()"
        >
          <v-text-field
            v-model="data.name"
            :rules="[rules.required]"
            label="Organisation Name"
            outlined
          ></v-text-field>

          <v-text-field
            v-model="data.contactName"
            :rules="[rules.required]"
            label="Organisation Contact Person (Name)"
            outlined
          ></v-text-field>

          <v-text-field
            v-model="data.contactEmail"
            :rules="[rules.required, rules.email]"
            label="Organisation Contact Person (Email)"
            outlined
          ></v-text-field>

          <v-text-field
            v-model="data.contactPhone"
            :rules="[rules.required]"
            label="Organisation Contact Person (Phone)"
            type="number"
            outlined
          ></v-text-field>

          <v-text-field
            v-model="data.hexCode"
            :rules="[rules.required]"
            label="Organisation Hex Code"
            outlined
          ></v-text-field>

          <v-file-input
            :rules="[rules.required, (value) => value.size < 2000000 || 'Image should be less than 2mb']"
            placeholder="Only accepts .png and .jpg files"
            accept="image/png, image/jpeg"
            prepend-icon=""
            label="Organisation Logo"
            v-model="file"
            show-size
            outlined
            dense
          />

          <v-btn
            @click="validateForm()"
            :loading="status.adding || loading"
            color="primary"
          >Add</v-btn>
        </v-form>
      </v-card-text>
    </v-card>
    <v-container fluid>
      <v-card-title :class="$classes.cardTitle">
          <span class="font-weight-bold">Organisations</span>
      </v-card-title>
      <v-card-text class="pa-0">
        <v-card-text v-if="!organisations.length">
          <v-alert
            type="info"
            border="left"
            text
          >No Organisations found.</v-alert>
        </v-card-text>

        <v-simple-table v-if="organisations.length">
          <template v-slot:default>
            <thead>
              <tr>
                <th>Name</th>
                <th>Contact Person (name)</th>
                <th>Contact Person (email)</th>
                <th>Contact Person (phone)</th>
                <th>Hex Code</th>
                <th>Logo</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="org in organisations" :key="org.id">
                <td class="blue-grey--text font-weight-medium">
                  {{ org.name}}
                </td>
                <td class="blue-grey--text font-weight-medium">
                  {{ org.contactName}}
                </td>
                <td><a :href="`mailto:${org.contactEmail}`">{{ org.contactEmail }}</a></td>
                <td class="blue-grey--text text--lighten-2">
                  {{ org.contactPhone}}
                </td>
                <td class="blue-grey--text text--lighten-2">
                  {{ org.hexCode}}
                </td>
                <td>
                  <img :src="org.logo" height="55px" width="55px"/>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-container>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import rules from '@/rules'
import firebase from 'firebase'

export default {
  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      rules,
      data: {},
      file: null,
      loading: false,
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      status: state => state.organisation.status,
      organisations: state => state.organisation.organisations
    })
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('organisation', [
      'addOrganisation',
      'getAllOrganisations'
    ]),

    validateForm() {
      if (this.$refs.orgForm.validate()) {
        this.loading = true
        Promise.resolve(
          this.$store.dispatch('file_upload/uploadFile', {
            file: this.file,
            folder: 'org_logos'
          })
        )
        .then(async (file) => {
          this.loading = false
          this.$store.dispatch('showSuccess', 'File uploaded')

          firebase.storage()
          .ref(`org_logos/${file.file}`)
          .getDownloadURL()
          .then(async (url) => {
            await this.addOrganisation({ ...this.data, logo: url})

            this.$refs.orgForm.reset()
          })
          .catch(error => {
            console.log(error)
          })
        })
      }
    }
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    if(!this.organisations.length)
      this.getAllOrganisations()
  }
}
</script>